<template>
  <div id="other-report" class="container">

    <h3>ยอดขาด/เกิน</h3>
    <CardDateFrom :branches="[]" @selected="searchData" />

    <div v-if="items.length > 0">
      <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
      <div class="card mb-3 py-3">
        <bar-chart :chartdata="chartCollection" :options="chartOptions"></bar-chart>
        <div class="text-center mt-4">
          <b-form-checkbox-group
            v-model="chartSelected"
            :options="chartSelectOptions"
            name="chart-select"
          ></b-form-checkbox-group>
        </div>
      </div>
    </div>

    <div v-if="isManager" class="alert alert-info">
      <b-form-group v-if="branchOptions.length" class="mb-1" label="เลือกดูทีม:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="branchFilters"
          :options="branchOptions"
          :aria-describedby="ariaDescribedby"
          name="branchFilters"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
    <div class="card">
      <table class="table table-sm table-bordered mb-0">
        <thead>
          <tr>
            <th class="text-center">เหตุผล</th>
            <th style="width: 15%;" class="text-center">ยอดเข้า</th>
            <th style="width: 15%;" class="text-center">ยอดออก</th>
            <th style="width: 15%;" class="text-center">รวม</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in itemGroups">
            <td>{{item.item_name}} <i class="fas fa-search-plus text-primary view-info" @click="viewInfo(item)"></i></td>
            <td class="text-right"><span v-html="numberFormat(item.incoming)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.outgoing)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.total, 2, '0.00')"></span></td>
          </tr>
        </tbody>
        <tbody v-if="!items.length">
          <tr>
            <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0" style="border-top: 3px double #ccc;">
          <tr style="font-size: 130%">
            <th class="text-center">รวม</th>
            <th class="text-right"><span v-html="numberFormat(total.incoming)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.outgoing)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.total)"></span></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />

    <ViewOtherModal :data="viewOtherItems" :is-show="isShowOtherModal" @close="modalClosed" />
  </div>
</template>
<script>
import AppConfig from '@/configs/app.config.js'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import _ from 'lodash'

import Swal from 'sweetalert2'

import moment from '@/helpers/moment'

import ReportService from '@/services/ReportService'

import BarChart from '@/views/components/BarChart'
import ViewOtherModal from './components/ViewOtherModal'
import CardDateFrom from '@/views/components/CardDateFrom'

export default {
  name: 'BranchReportAgent',
  components: {
    Loading,
    BarChart,
    ViewOtherModal,
    CardDateFrom
  },
  data() {
    return {
      isLoading: false,
      dataDate: null,
      successDataDate: null,
      selectedBranch: null,
      data: null,

      chartSelected: ['incoming', 'outgoing'],
      chartSelectOptions: [
        { text: 'ยอดเข้า', value: 'incoming' },
        { text: 'ยอดออก', value: 'outgoing' },
        // { text: 'ยอดรวม', value: 'total' }
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },

      isShowOtherModal: false,
      viewOtherItems: {
        itemName: '',
        items: []
      },
      branchFilters: []
    }
  },
  computed: {
    isManager() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      return /*LoggedInData?.userRole === 'Owner' ||*/ LoggedInData?.role === 'Manager' || LoggedInData?.role === 'Admin'
    },
    dataDateText() {
      if(!this.successDataDate) {
        return '-'
      }

      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1

      if(this.successDataDate.start === this.successDataDate.end) {
        return `${this.successDataDate.start}`
      }else{
        return `${this.successDataDate.start} ถึง ${this.successDataDate.end}`
      }
    },
    OtherItems() {
      return this.data?.OtherItems || []
    },
    items() {
      if(!this.data)
        return []

      return this.data?.items.filter((item)=>{
        if(!this.branchFilters.length)
          return true

        return this.branchFilters.includes(item.owners.accountId)
      })
      .map((item)=>{
        const amount = item?.amount?.number || 0
        item.incoming = amount > 0 ? amount : 0
        item.outgoing = amount < 0 ? amount : 0
        item.total = amount
        return item
      })
    },
    itemGroups() {
      return this.OtherItems.map((other)=>{
        const amount = this.items.reduce((total, item)=>{
          if(other._id === item.item_id) {
            total.incoming += item.incoming
            total.outgoing += item.outgoing
            total.total += item.total
          }
          return total
        }, {
          incoming: 0,
          outgoing: 0,
          total: 0
        })
        return {
          ...other,
          ...amount
        }
      })
      .filter((item)=>{
        return item.incoming !== 0 || item.outgoing !== 0 || item.total !== 0
      })
    },
    total() {
      return this.items.reduce((total, item)=>{
        total.incoming += item.incoming
        total.outgoing += item.outgoing
        total.total += item.total
        return total
      }, {
        incoming: 0,
        outgoing: 0,
        total: 0
      })
    },
    chartCollection() {

      const labels = this.items.map((item)=>{
        return item.item_name
      })

      let datasets = {}
      datasets.incoming = {
        label: 'ยอดเข้า',
        backgroundColor: '#c3e6cb',
        data: this.items.map((item)=>{
          return Math.abs(item.incoming || 0)
        })
      }

      datasets.outgoing = {
        label: 'ยอดออก',
        backgroundColor: '#f5c6cb',
        data: this.items.map((item)=>{
          return Math.abs(item.outgoing || 0)
        })
      }

      datasets.total = {
        label: 'ยอดรวม',
        backgroundColor: '#b8daff',
        data: this.items.map((item)=>{
          return item.total || 0
        })
      }

      return {
        labels: labels,
        datasets: this.chartSelected.map((select)=>{
          return datasets[select]
        })
      }
    },
    Branchs() {
      return this.data?.Branchs || []
    },
    branchOptions() {
      return this.Branchs.map((branch)=>{
        return {
          text: branch.name,
          value: branch._id
        }
      })
    }
  },
  methods: {
    searchData(params) {
      this.dataDate = params?.date
      this.selectedBranch = params?.branch

      this.isLoading = true
      ReportService.getOthers(this.dataDate, this.selectedBranch)
      .then((response)=>{
        if(response.status === 'success') {
          this.successDataDate = this.dataDate
          this.data = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    numberFormat(number, dec=2, def='-') {
      if(!number || number==='-')
        return def

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    },
    modalClosed() {
      this.isShowOtherModal = false
    },
    viewInfo(item) {
      this.viewOtherItems = {
        itemName: item.item_name,
        items: this.items.filter((d)=>{
          return d.item_id === item._id
        })
      }
      this.isShowOtherModal = true
    }
  }
}
</script>
<style lang="scss" scoped>
#other-report {
  position: relative;
  padding-bottom: 50px;

  .view-info {
    cursor: pointer;
  }
}
</style>
