<template>
  <b-modal
    id="other-item-madal"
    ref="other-item-madal"
    no-close-on-backdrop
    hide-footer
    @show="resetModal"
    @hidden="resetModal"
    size="xl"
  >

    <template #modal-header="{ close }">
      <div>
        <h4 class="text-info">{{title}}</h4>
      </div>
      <b-button size="sm" variant="outline" @click="close()">
        <i class="fas fa-times"></i>
      </b-button>
    </template>
    <table class="table table-sm table-bordered mb-0">
      <thead>
        <tr>
          <th style="width: 15%;" class="text-center">วันที่</th>
          <th style="width: 30%;">รายการ</th>
          <th>รายละเอียด</th>
          <th class="text-right" style="width: 15%;">จำนวน</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in items">
          <td class="text-center">{{item.date}}</td>
          <td>{{item.item_name}}</td>
          <td>{{item.description}}</td>
          <td class="text-right">
            <span v-html="numberFormat(item.amount.number)"></span>
          </td>
        </tr>
        <tr v-if="!items.length">
          <td colspan="3" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

  </b-modal>
</template>
<script>
import RecordService from '@/services/RecordService'
import Swal from 'sweetalert2'

export default {
  name: 'ViewOtherModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    title() {
      return this.data?.itemName || ''
    },
    items() {
      return this.data?.items || []
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.isProcess = false
    },
    showModal() {
      this.$refs['other-item-madal'].show()
    },
    hideModal() {
      this.$refs['other-item-madal'].hide()
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#other-item-madal {

  .modal-xl {
    max-width: 800px;

    .modal-body {
      padding: 0;

      .table {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
